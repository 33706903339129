import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

import MarkdownBody from '../../../components/MarkdownBody'

const Page404 = props => {
  const { markdownRemark: { html, frontmatter: { title } }} = useStaticQuery(graphql`
    query Page404 {
      markdownRemark(fileAbsolutePath: {regex: "/_404/"}) {
        html
        frontmatter {
          title
        }
      }
    }
  `)
  
  return (
  <Layout {...props}>
    <Seo title={title} />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header={title}
            subheader='You may have mistyped the address or the page may have moved.'
          />
        </Section>
        <MarkdownBody html={html} />
      </Main>
    </Stack>
  </Layout>
  )
}

export default Page404
